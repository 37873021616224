<template>
<div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-primary p-4">
                    <div class="card-header">
                        <h2 class="card-title"><span v-if="news.date != null">Neuigkeit vom {{ news.date | moment }}</span></h2>
                    </div>

                    <div class="card-body" v-if="notEmptyObject(news)">
                        <div class="row align-items-center">
                            <div class="col-6">
                                <h3>{{news.title}}</h3>
                            </div>
                            <div class="col-6">
                                <img :src="news.img_mobile" width="200" max-height="auto"/>
                            </div>
                            <div class="col-md-12 p-2">
                                <span v-html="news.description"></span>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer">
                        <p>Verfasst von: {{ news.author.first_name }}</p>
                    </div>

                </div>          
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment'

export default {
    name: "NewsShow",
    title: "Neuigkeiten",
    data() {
        return {
            sortBy: "created_at",
            sortDesc: false,
            params: {
                sort_field: 'created_at',
                sort_direction: 'desc',
                per_page: 25,
            },
            page: 1,
            news: {
                author: [], 
            }, 
        }
    },


    filters: {
        moment: function (date) {
            return moment(date).format('DD.MM.YYYY');
        }
    },

    methods: {

        loadNews() {
            this.axios
                .get("/news/" + this.$route.params.id)
                .then((response) => {
                    this.news = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
    },
    created() {
        this.loadNews();
    },
}
</script>

<style scoped>

::v-deep img {
    max-width: 80vh;
}

</style>
